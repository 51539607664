<script setup>
import { QuillEditor } from '@vueup/vue-quill'
import { ref, computed, defineProps, defineEmits, reactive } from 'vue';
import { useValidationStore } from '~/stores/account/validations.store';

const props = defineProps({
	disabled: { type: Boolean, default: false },
	placeholder: { type: String, default: '' },
	editorTheme: { type: String, default: 'snow' },
	editor: Boolean,
	editorCustumWidth: { type: String, default: 'w-100' },
	readonly: { type: Boolean, default: false },
	isPrice: { type: Boolean, default: false },
	value: [Object, String, Number],
	input: { type: Function, default: () => {} },
	multiLanguage: Boolean,
	name: { type: [String, Number], required: true },
	type: String,
	change: { type: Function, default: () => {} },
	backgroundColor: { type: String, default: 'bg-gray-50' },
	border: { type: String, default: 'rounded' },
	ring: { type: String, default: 'focus:ring-blue-500' },
	showButton: { type: Boolean, default: false },
	required: Boolean,
	options: { type: Array, default: () => [] },
	selectMultiple: { type: Boolean, default: false },
	isThereShowed: { type: Boolean, default: true },
	select: { type: Boolean, default: false },
	searchable: { type: Boolean, default: true },
	trackBy: String,
	label: String,
	defaultOption: String,
	icon: {type: Boolean, default: false},
	isPercentage: { type: Boolean, default: false },
	customSearchBy: { type: Array, default: () => []},
});

const validationStore = useValidationStore();
const validationError = computed(() => validationStore.validations);

const inputEditor = ref(null);

const nameComputed = computed(() => props.name?.toString());

const showedValue = computed({
	get: () => {
		if (props.select == true && props.trackBy && !props.selectMultiple) return props.options.find((option) => option[props.trackBy] === valueComputed.value);
		if (props.selectMultiple == true && props.trackBy != undefined) return valueComputed.value.map((val) => props.options.find((option) => option[props.trackBy] == val));
		if (props.isPrice) {
			// return helper.currencyFormat(valueComputed.value);
		}
		return (props.multiLanguage ? valueComputed.value[showed_language.value] : valueComputed.value) ?? '';
	},
	set: (val) => {
		validationStore.resetValidationByNameAction(props.name);
		if (val == null) return;
		let checkedValue = val;
		let currentValue = valueComputed.value;
		if (props.select && props.trackBy != undefined) checkedValue = val[props.trackBy];
		if (props.selectMultiple && props.trackBy != undefined) checkedValue = val.map((val) => val[props.trackBy]);
		if (props.isPrice) {
			checkedValue = prepareInput(val);
			// checkedValue = helper.currencyFormat(checkedValue);
		}
		props.multiLanguage ? (currentValue[showed_language.value] = checkedValue) : (currentValue = checkedValue);
		emit('input', currentValue);
		if (props.isPrice) {
			emit('update:value', prepareInput(currentValue));
		} else {
			emit('update:value', currentValue);
		}
		props.input(currentValue);
	},
});


</script>

<template>
	<ClientOnly>
		<QuillEditor
			ref="inputEditor"
			toolbar="full"
			:options="{ placeholder: placeholder }"
			style="height: 300px"
			:theme="editorTheme"
			contentType="html"
			v-model:content="showedValue"
			:id="nameComputed + '_id'"
			class="break-all block p-4 text-sm text-gray-900 rounded-lg bg-gray-50 duration-300"
			:class="[border, ring]">
		</QuillEditor>
	</ClientOnly>
</template>